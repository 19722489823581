<header class="header">
  <div class="wide-section p-t-3 p-b-5">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
      <a routerLink="/">
        <img alt="체크팀" class="logo-m-img" ngClass.gt-xs="log-img" src="/assets/img/bi/bi-m.svg"
             src.gt-xs="/assets/img/bi/bi.svg">
      </a>

      <div fxLayout="row" fxLayoutGap="10px">
        <div *ngIf="!mobile" fxLayout="row" fxLayoutGap="25px">
          <button [matMenuTriggerFor]="analysis" mat-button>
            <span>부동산 권리분석</span>
            <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
          </button>
          <mat-menu #analysis="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/analysis">신규 권리분석</button>
            <button mat-menu-item routerLink="/analysis/list">권리분석 보고서</button>
            <button mat-menu-item routerLink="/analysis/info">서비스 안내</button>
          </mat-menu>

          <button [matMenuTriggerFor]="myHouse" mat-button>
            <span>부동산 등기변경 알림</span>
            <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
          </button>
          <mat-menu #myHouse="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/house/list">등기변경 리스트</button>
            <button [queryParams]="{type: 'MY_HOUSE'}" mat-menu-item routerLink="/house/register">서비스 신청</button>
            <button mat-menu-item routerLink="/house/batch">일괄 신청</button>
            <button mat-menu-item routerLink="/house/extends">일괄 연장</button>
            <button mat-menu-item routerLink="/coupon">쿠폰 구입</button>
            <button mat-menu-item routerLink="/info/my-house">서비스 안내</button>
          </mat-menu>

          <button [matMenuTriggerFor]="openHouse" mat-button>
            <span>오픈부동산</span>
            <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
          </button>
          <mat-menu #openHouse="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/house/open">오픈부동산 알림신청</button>
            <button [queryParams]="{type: 'OPEN_HOUSE'}" mat-menu-item routerLink="/house/register">오픈부동산 등록</button>
            <button mat-menu-item routerLink="/house/extends">일괄 연장</button>
            <button mat-menu-item routerLink="/coupon">쿠폰 구입</button>
            <button mat-menu-item routerLink="/info/open-house">서비스 안내</button>
          </mat-menu>

          <button mat-button>
            <a [matMenuTriggerFor]="serviceMenu">
              <span>서비스</span>
              <span class="material-symbols-outlined c-gray mat-icon-size-25">arrow_drop_down</span>
            </a>
          </button>

          <mat-menu #serviceMenu="matMenu" xPosition="before">
            <button [queryParams]="{type: 'EVENT'}" mat-menu-item routerLink="/info/events">이벤트</button>
            <button [queryParams]="{type: 'NOTICE'}" mat-menu-item routerLink="/info/notice">공지사항</button>
            <button [queryParams]="{type: 'INQUIRE'}" mat-menu-item routerLink="/inquire">1 : 1 문의</button>
            <button [queryParams]="{type: 'ERROR'}" mat-menu-item routerLink="/inquire">오류 신고</button>
            <button [queryParams]="{type: 'SUGGEST'}" mat-menu-item routerLink="/inquire">기능 요청</button>
            <button mat-menu-item routerLink="/info/company">회사소개</button>
          </mat-menu>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" *ngIf="!isLogin">
          <button class="bg-gray-light hover-primary" mat-flat-button ngClass.xs="f-12" routerLink="/join">회원가입</button>
          <button (click)="login()" class="bg-gray-light hover-primary" mat-flat-button
                  ngClass.xs="f-12">로그인
          </button>
        </div>

        <div fxLayout="row" *ngIf="isLogin">
          <button mat-button routerLink="/notification">
            <lord-icon class="va-middle" ngClass.gt-xs="img-size-wh-27" ngClass.xs="img-size-wh-27"
                       src="assets/json/notification-bell-outline.json" trigger="hover"></lord-icon>
            <span [matBadge]="countNotifications"></span>
          </button>
          <div>
            <button mat-button [matMenuTriggerFor]="myHome" ngClass.xs="m-r-m10">
              <lord-icon class="va-middle" ngClass.gt-xs="img-size-wh-27" ngClass.xs="img-size-wh-27" src="assets/json/account-outline.json"
                         trigger="hover"></lord-icon>
            </button>
            <mat-menu #myHome="matMenu" xPosition="before">
              <button mat-menu-item routerLink="/home">마이페이지</button>
              <button mat-menu-item routerLink="/home/info">회원정보변경</button>
              <button mat-menu-item routerLink="/home/sns">SNS 로그인 설정</button>
              <button mat-menu-item routerLink="/home/purchase">결제내역</button>
              <button mat-menu-item routerLink="/home/coupon">쿠폰함</button>
              <button mat-menu-item (click)="logout()">로그아웃</button>
            </mat-menu>
          </div>

        </div>
      </div>
    </mat-toolbar>
  </div>
</header>

<div ngClass.gt-xs="p-t-70" ngClass.xs="p-v-60">
  <ngx-pull-to-refresh
    (refresh)="myRefreshEvent($event)"
    [distanceForRefresh]="200"
    [isEnable]="enableRefresh"
    [spinnerSize]="50"
    [targetElement]="targetElement"
    spinnerColor="#2db7b7">

    <router-outlet></router-outlet>
  </ngx-pull-to-refresh>
</div>

<app-scroll></app-scroll>

<mat-toolbar *ngIf="mobile" [ngClass]="{'iphone-padding': ios}" class="mobile-bottom-toolbar mat-elevation-z3 p-v-33 c-gr-primary-mint">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex>

<!--    <div class="cursor icon-text" fxFlex fxLayout="column" fxLayoutAlign="start center"-->
<!--         routerLink="/analysis/list">-->
<!--      <mat-icon [ngClass]="activeMobileFooterIcon === 'ANALYSIS' ? 'c-gr-primary-mint' : 'c-gray'">-->
<!--        <span class="material-symbols-outlined xs-size f-500">monitor_heart</span>-->
<!--      </mat-icon>-->
<!--      <div class="f-11 f-600">권리분석</div>-->
<!--    </div>-->
<!--    <div class="cursor icon-text" fxFlex fxLayout="column" fxLayoutAlign="start center"-->
<!--         routerLink="/analysis">-->
<!--      <mat-icon [ngClass]="activeMobileFooterIcon === 'ANALYSIS' ? 'c-gr-primary-mint' : 'c-gray'">-->
<!--        <span class="material-symbols-outlined xs-size f-500">mindfulness</span>-->
<!--      </mat-icon>-->
<!--      <div class="f-11 f-600">신규분석</div>-->
<!--    </div>-->

    <div (click)="openBottomSheet('ANALYSIS')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'ANALYSIS' ? 'c-gr-mint-primary' : 'c-gray-deep'">
        <span class="material-symbols-outlined xs-size f-500">mindfulness</span>
      </mat-icon>
      <div class="f-11 f-600">권리분석</div>
    </div>

    <div (click)="openBottomSheet('HOUSE')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'HOUSE_REGISTER' || activeMobileFooterIcon === 'MY_HOUSE' ? 'c-gr-mint-primary' : 'c-gray-deep'">
        <span class="material-symbols-outlined xs-size f-500">circle_notifications</span>
      </mat-icon>
      <div class="f-11 f-600">등기변경알림</div>
    </div>

    <div (click)="openBottomSheet('OPEN_HOUSE')" class="cursor icon-text" fxFlex fxLayout="column"
         fxLayoutAlign="start center">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'OPEN_HOUSE' ? 'c-gr-mint-primary' : 'c-gray-deep'">
        <span class="material-symbols-outlined xs-size">tooltip</span>
      </mat-icon>
      <div class="f-11 f-600">오픈 부동산</div>
    </div>

    <div class="cursor icon-text" fxFlex fxLayout="column" fxLayoutAlign="start center"
         (click)="openBottomSheet('SERVICE')">
      <mat-icon [ngClass]="activeMobileFooterIcon === 'SERVICE' ? 'c-gr-mint-primary' : 'c-gray-deep'">
        <span class="material-symbols-outlined xs-size f-400">web_stories</span>
      </mat-icon>
      <div class="f-11 f-600">더보기</div>
    </div>

<!--    <div (click)="openBottomSheet('HOME')" *ngIf="isLogin" class="cursor icon-text" fxFlex fxLayout="column"-->
<!--         fxLayoutAlign="start center" fxLayoutGap="0">-->
<!--      <div [ngClass]="activeMobileFooterIcon === 'HOME' ? 'text-circle-32 bg-gray-dark' : 'text-circle-32'">-->
<!--        <mat-icon [ngClass]="activeMobileFooterIcon === 'HOME' ? 'c-white' : 'c-dark'" class="mat-icon-size-28">-->
<!--          <span class="material-symbols-outlined xs-size">account_circle</span>-->
<!--        </mat-icon>-->
<!--      </div>-->
<!--      <div class="f-10 f-600">마이 페이지</div>-->
<!--    </div>-->

  </div>
</mat-toolbar>

<ngx-spinner [fullScreen]="true" bdColor="rgba(0, 0, 0, 0.8)" color="#fff" size="medium" type="ball-climbing-dot">
  <p style="color: white"> 정보 확인 중 ...... </p>
</ngx-spinner>
