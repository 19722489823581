import {Component} from '@angular/core';
import {NativeService} from '../../_service/native.service';
import {NGXLogger} from "ngx-logger";

declare let window: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  showStoreLink = false;
  partnerLogos = [
    'assets/img/partner/logo-round-8percent.png',
    'assets/img/partner/logo-round-cocktailfunding.png',
    'assets/img/partner/logo-round-zoomfund.png',
    'assets/img/partner/logo-round-oasisfund.png',
    'assets/img/partner/logo-round-yfund.png',
    'assets/img/partner/logo-round-leadingplus.png',
    'assets/img/partner/logo-round-acefunding.png',
    'assets/img/partner/logo-round-fmfunding.png',
    'assets/img/partner/logo-round-dailyfunding.png',
    'assets/img/partner/logo-round-miracle.png',
    'assets/img/partner/logo-round-namo.png',
    'assets/img/partner/logo-round-daonfunding.png',
    'assets/img/partner/logo-round-smartfunding.png',
    'assets/img/partner/logo-round-titaninvest.png'
  ]

  constructor(private readonly log: NGXLogger,
              private readonly nativeService: NativeService) {
    this.log.debug('START Main Component');
    this.showStoreLink = !this.nativeService.isNative();
  }

  openPlayStore() {
    window.open('https://play.google.com/store/apps/details?id=kr.co.lawalliance.checkteam')
  }

  openAppStore() {
    if (this.nativeService.isiOS) {
      window.open('itms-apps://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    } else {
      window.open('https://itunes.apple.com/kr/app/apple-store/id1578403569?mt=8');
    }
  }
}
