<div *ngIf="notice" ngClass.gt-xs="bg-gray-light" ngClass.xs="bg-white p-b-15">
  <section class="banner-xs p-b-2">
    <div class="cursor" ngClass.xs="p-l-20 p-r-5">
      <div (click)="openNotice()" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="7px">
        <div class="notice-badge">긴급공지</div>
        <div class="f-12 lh-1-7 f-600" ngClass.gt-xs="f-13 f-600" ngClass.xs="lh-1-8">
          {{ notice.title }}
          <span ngClass.xs="c-gray" ngClass.gt-xs="p-l-10" *ngIf="isIrosNotice"><br
            *ngIf="isMobile"/>기간ㅣ{{ notice.displayMainStartAt | date: 'yyyy.MM.dd. HH : mm' }}
            - {{ notice.displayMainEndAt | date: 'MM.dd. HH : mm' }}</span>
        </div>
      </div>
    </div>
  </section>
</div>

<!--main visual start-->
<div ngClass.gt-xs="p-t-30 main-top-wave">
  <section class="wide-section banner-xs" ngClass.xs="m-b-60 main-top-wave-xs">
    <div ngClass.xs="p-b-10">
      <app-swiper paginationType="fraction" swiperId="fraction">
        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start"
               fxLayoutGap.xs="20px">
            <div fxFlex.xs fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10 ff-sc-7"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 ff-sc-7 m-b-5">
                    부동산 분석! 어렵고 걱정되시죠!
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-subtitle-3 f-500 ff-sc-5 m-b-20 lh-1-5">
                    체크팀으로 <span ngClass.gt-xs="f-600 bg-neon-primary" ngClass.xs="f-700">내일의 위험까지 미리 분석</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">부동산 담보대출 분석</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">전세보증금 진단</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">부동산 자산가치 분석</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">부동산 매매계약 진단</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">부동산경매 시뮬레이션</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-purple-light">가맹점 부동산 담보가치 분석</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="end end" class="m-t-m15 m-r-m20">
                    <!--                    <img alt="권리분석" class="img-size-150" src="assets/img/glass/analy-mint-primary.svg">-->
                    <img alt="권리분석" class="img-size-200 icon-tran-scalex" src="assets/img/character/analyzes-the-data-purple.svg">
                  </div>

                  <div fxShow fxHide.gt-xs fxLayout="column" class="p-b-10">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">부동산 담보대출 분석</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">전세보증금 진단</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">자산가치 분석</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">매매계약 진단</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">경매 시뮬레이션</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-primary c-white">가맹점 담보 분석</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <div>
<!--                <img alt="부동산권리분석" class="img-size-270" src="assets/img/glass/analy-mint-primary.svg">-->
                <img alt="부동산권리분석" class="img-size-270 icon-tran-scalex" src="assets/img/character/analyzes-the-data-purple.svg">
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60"  routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start"
               fxLayoutGap.xs="20px">
            <div fxFlex.xs fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 ff-sc-7 m-b-5">
                    우리집 전세보증금 분석 !
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-subtitle-3 f-500 ff-sc-5 m-b-20 lh-1-5">
                    전세보증금 <span ngClass.gt-xs="f-600 bg-neon-pink" ngClass.xs="f-700">안전성과 위험권리를 확인</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">보증금 안전성</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">법원 배당표</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">권리 시뮬레이션</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">위험 권리 확인</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-pink-light">전세 시세</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="end end" class="m-t-m15 m-r-m20">
                    <img alt="권리분석" class="img-size-200 icon-tran-scalex" src="assets/img/character/protects-personal.svg">
                  </div>
                  <div fxShow fxHide.gt-xs fxLayout="column" class="p-b-10">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-pink c-white">보증금 안전성</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink c-white">법원 배당표</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink c-white">권리 시뮬레이션</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink c-white">위험 권리 확인</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-pink c-white">전세 시세</div>
                    </div>
                  </div>
                  <div fxHide fxLayoutAlign="end end" class="m-t-m45 m-b-m20 m-r-m20">
                    <img alt="권리분석" class="img-size-150" src="assets/img/glass/check-mint-pink.svg">
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <div>
                <img alt="부동산권리분석" class="img-size-270 icon-tran-scalex" src="assets/img/character/protects-personal.svg">
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60"  routerLink="/analysis/info">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start">
            <div fxFlex fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 f-600 m-b-5">
                    부동산 권리분석
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 ff-sc-7 m-b-5">
                    <span fxHide fxShow.gt-xs>부동산 등기부</span>등본 확인에서 배당분석까지. 55초!
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-subtitle-3 f-500 ff-sc-5 m-b-20 lh-1-5">
                    분석 업무를 <span ngClass.gt-xs="f-600 bg-neon-accent" ngClass.xs="f-700">빠르고 효율적으로 자동화</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-accent-light">권리분석 보고서</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-accent-light">법원 배당표</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-accent-light">보고서 다운로드</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-accent-light">등본 자동발급</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-accent-light">시세 확인</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="end end" class="m-t-m15 m-r-m20">
                    <img alt="권리분석" class="img-size-200" src="assets/img/character/filters-data-accent.svg">
                  </div>
                  <div fxShow fxHide.gt-xs fxLayout="column" class="p-b-10">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs m-b-7 bg-accent c-white">권리분석 보고서</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-accent c-white">법원 배당표</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-accent c-white">보고서 다운로드</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-accent c-white">등본 자동발급</div>
                      <div class="gray-bg-chip-xs m-b-7 bg-accent c-white">시세 확인</div>
                    </div>
                  </div>

                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>

            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <div>
                <img alt="부동산권리분석" class="img-size-270" src="assets/img/character/filters-data-accent.svg">
              </div>
            </div>


          </div>
        </swiper-slide>


        <swiper-slide fxLayout="column" ngClass.gt-xs="p-l-60" routerLink="/info/my-house">
          <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-between start">
            <div fxFlex fxFlex.gt-xs="70">
              <div ngClass.gt-xs="p-v-40" ngClass.xs="p-h-22 p-v-30">
                <div>
                  <div ngClass.gt-xs="mat-subtitle-2 m-b-10"
                       ngClass.xs="mat-body-1 ff-sc-6 m-b-5">
                    부동산 등기변경알림
                  </div>
                  <div ngClass.gt-xs="mat-headline-6 m-b-10"
                       ngClass.xs="mat-subtitle-1 ff-sc-7 m-b-5">
                    한번의 알림신청으로 !
                  </div>
                  <div ngClass.gt-xs="mat-subtitle-1 lh-1-6 m-b-25"
                       ngClass.xs="mat-subtitle-3 f-500 ff-sc-5 m-b-20 lh-1-5">
                    위험 등기정보를 <span ngClass.gt-xs="f-600 bg-neon-mint c-white" ngClass.xs="f-700">자동으로 간편하게 확인</span> 하세요.
                  </div>
                  <div fxLayout="column" class="p-b-20" fxHide fxShow.gt-xs>
                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                         fxLayoutGap="5px">
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">부동산 담보</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">부동산 계약</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">가맹점 담보</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">임차인</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">채권자</div>
                      <div class="gray-bg-chip-lg m-b-7 bg-mint-light c-black">소유자</div>
                    </div>
                  </div>
                  <div fxShow fxHide.gt-xs fxLayoutAlign="end end" class="m-t-m15 m-r-m20">
                    <img alt="권리분석" class="img-size-200" src="assets/img/character/likes-shopping-mintlight.svg">
                  </div>

                  <div fxLayout="column" class="p-b-20" fxHide.gt-xs fxShow>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="3px">
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">부동산 담보</div>
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">부동산 계약</div>
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">가맹점 담보</div>
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">임차인</div>
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">채권자</div>
                      <div class="gray-bg-chip-xs bg-mint c-white m-b-7">소유자</div>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-xs ngClass.gt-xs="mat-body-1 cursor p-l-5"><u>&nbsp;자세히 보기&nbsp;</u></div>
                </div>
              </div>
            </div>
            <div fxHide fxShow.gt-xs fxFlex.gt-xs="30" fxLayoutAlign.gt-xs="center start">
              <img alt="부동산권리분석" class="img-size-270" src="assets/img/character/likes-shopping-mint.svg">
            </div>
          </div>
        </swiper-slide>
      </app-swiper>
    </div>
  </section>
</div>
<!--main visual end-->

<!--퀵 메뉴-->
<section class="banner p-t-20 p-b-10">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px"
       ngClass.gt-xs="p-b-20" ngClass.xs="p-v-15 p-h-15 m-t-m110">

    <div fxFlex="100" fxFlex.gt-xs="30" id="member">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-white-blur c-dark shadow-box-xs p-t-10 p-b-20"
           ngClass.xs="bg-white shadow-box-xs p-h-20 p-v-10 p-b-20 m-b-45">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-20">
            <div fxHide fxShow.gt-xs>
              <span class="material-symbols-outlined c-gr-mint-accent f-75">key</span>
            </div>
            <div fxShow fxHide.gt-xs>
              <span class=" material-symbols-outlined c-gr-mint-primary f-70">key</span>
            </div>
          </div>
          <div ngClass.gt-xs="m-t-5 p-h-30" ngClass.xs="p-h-10 m-t-m10">
            <div>
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                   ngClass.xs="mat-subtitle-2 p-t-5 m-b-3 ff-sc-7 f-700">
                <span>로그인 해주세요 :)</span><span fxHide>마이 부동산</span>
              </div>
              <div ngClass.gt-xs="mat-body-2 f-600" ngClass.xs="mat-body-1 f-600">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div class="p-r-20"  routerLink="/analysis/list">
                    권리분석
                    <span class="p-l-5 f-600 ff-sc-6">1</span>
                  </div>
                  <div routerLink="/house/list">
                    등기변경알림
                    <span class="p-l-5 f-600 ff-sc-6">10</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="35">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-primary-blur shadow-box hover-shadow c-black p-t-10 p-b-20"
           ngClass.xs="bg-primary-blur-xs c-black p-h-20 p-v-10 m-b-30"
           routerLink="/analysis">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-10">
            <div fxHide fxShow.gt-xs fxLayoutAlign="end">
              <span class="material-symbols-outlined c-primary f-70">order_approve</span>
            </div>
            <div fxShow fxHide.gt-xs fxLayoutAlign="start">
              <span class="material-symbols-outlined c-gr-mint-primary f-70 m-l-m5">order_approve</span>
            </div>
          </div>
          <div ngClass.gt-xs="m-t-5 p-h-30" ngClass.xs="m-t-m5">
            <div ngClass.xs="p-b-10 p-h-10">
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                   ngClass.xs="mat-subtitle-2 p-t-5 m-b-3 ff-sc-7 f-700">
                부동산 권리분석
              </div>
              <div ngClass.gt-xs="mat-body-2 f-700" ngClass.xs="mat-body-1 f-600">
                담보분석 &middot; 전세보증금 진단 &middot; 자산가치분석 &middot; 경매분석
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="35" routerLink="/house/list">
      <div class="brd-15 cursor"
           ngClass.gt-xs="bg-mint-blur c-dark shadow-box hover-shadow p-t-10 p-b-20"
           ngClass.xs="bg-gray-light p-h-20 p-v-10 m-b-15"
           routerLink="/house/list">
        <div fxLayout="column">
          <div ngClass.gt-xs="p-h-10">
            <div fxHide fxShow.gt-xs  fxLayoutAlign="end">
              <span class="material-symbols-outlined c-mint f-600 f-70">notifications</span>
            </div>
            <div fxShow fxHide.gt-xs fxLayoutAlign="start">
              <span class=" material-symbols-outlined c-gr-mint-green f-600 f-70 m-l-m10">notifications</span>
            </div>
          </div>

          <div ngClass.gt-xs="m-t-5 p-h-30" ngClass.xs="m-t-m10">
            <div ngClass.xs="p-b-10 p-h-10">
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6"
                   ngClass.xs="mat-subtitle-2 p-t-5 m-b-3 ff-sc-7 f-700">
                부동산 등기변경 알림
              </div>
              <div ngClass.gt-xs="mat-body-2" ngClass.xs="mat-body-1 f-600">
                등기 변경알림보 &middot; 부동산등본 열람 &middot; 통계
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--이벤트-->

<div ngClass.gt-xs="p-v-30" ngClass.xs="p-h-15">
  <section class="banner-xs">
    <div fxLayout="row" fxLayoutGap="20px" class="of-auto">
      <div fxFlex fxFlex.xs="80vw" fxFlex.gt-xs="50" ngClass.gt-xs="bg-gray-light p-v-40 brd-15 cursor" ngClass.xs="bg-warn-light c-black p-v-30 brd-15" fxLayout="column" fxLayoutGap="10px" routerLink="/info/events">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.gt-xs="20px">
          <div>
            <div fxLayout.xs="row" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center" ngClass.xs="p-b-10 p-l-5">
              <div fxShow fxHide.gt-xs class="material-symbols-outlined c-gr-mint-primary f-80">ar_stickers</div>
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6 p-b-10 p-h-30"
                   ngClass.xs="mat-subtitle-3 p-t-5 m-b-0 ff-sc-7">권리분석하고 <div fxShow fxHide.gt-xs></div>등기알림 쿠폰 받으세요.</div>
            </div>

            <div ngClass.gt-xs="mat-body-1 f-600 m-b-0 p-l-30" ngClass.xs="p-h-20">
              <div>권리분석 이용시 등기변경알림 이용쿠폰 3매(3개월)가 지급됩니다.</div>
            </div>
          </div>
          <div fxHide fxShow.gt-xs class="p-r-10 m-b-m20">
            <div class="material-symbols-outlined c-gr-mint-primary f-110">ar_stickers</div>
          </div>
        </div>
      </div>

      <div fxFlex fxFlex.xs="80vw" fxFlex.gt-xs="50" ngClass.gt-xs="bg-gray-light p-v-40 brd-15 cursor" ngClass.xs="bg-amber-light p-v-30 brd-15" fxLayout="column" fxLayoutGap="10px" routerLink="/info/events">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap.gt-xs="20px">
          <div>
            <div fxLayout.xs="row" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center" ngClass.xs="p-b-10 p-l-5">
              <div fxShow fxHide.gt-xs class="material-symbols-outlined c-gr-mint-primary f-80 f-500">family_home</div>
              <div ngClass.gt-xs="mat-subtitle-2 m-b-3 ff-sc-6 p-b-10 p-h-30"
                   ngClass.xs="mat-subtitle-3 p-t-5 m-b-0 ff-sc-7">무료로 등기변경알림을 <div fxShow fxHide.gt-xs></div>이용해보세요</div>
            </div>

            <div ngClass.gt-xs="mat-body-1 f-600 m-b-0 p-l-30" ngClass.xs="p-h-20">
              <div>회원가입만 하면 등기변경알림과 부동산등기부등본 열람 쿠폰 1매가 지급됩니다.</div>
            </div>
          </div>
          <div fxHide fxShow.gt-xs class="p-r-10 m-b-m20">
            <div class="material-symbols-outlined c-gr-pink-primary-10 f-110">family_home</div>
          </div>
        </div>
      </div>


    </div>
  </section>
</div>

<div class="bg-gray-light">
  <section class="banner" fxLayout="column" fxLayout.gt-xs="row"
           fxLayoutAlign.gt-xs="space-around center" fxLayoutGap="30px" fxLayoutGap.gt-xs="40px"
           ngClass.gt-xs="m-b-15">

    <div ngClass.gt-xs="p-v-20 p-l-20" ngClass.xs="p-h-15">
      <div fxFlex.gt-xs="51">
        <div ngClass.gt-xs="p-t-40" ngClass.xs="p-h-10 p-t-30 p-b-40">
          <div ngClass.gt-xs="mat-subtitle-1 f-600 ff-sc-6 p-t-20 m-b-7"
               ngClass.xs="mat-subtitle-2 f-600 ff-sc-6 p-t-10 m-b-5">
            온투업 부동산 투자상품의
          </div>
          <div class="ff-sc-5 lh-1-7" ngClass.gt-xs="mat-headline-6 m-b-15" ngClass.xs="mat-subtitle-1 f-600 ff-sc-6">
            담보정보를 무료로 확인하세요!
          </div>
          <div ngClass.gt-xs="ff-sc-4 f-600 mat-body-1 m-b-5" ngClass.xs="ff-sc-4 f-600 mat-body-1 m-b-5">
            투자자는 알림신청만으로 투자부동산의 담보현황을 확인할 수 있습니다.
          </div>

          <div class="p-t-10" fxHide.gt-xs fxShow ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="25" fxLayoutAlign="start center">
                <div>
                  <img [src]="logo" alt="image" class="img-size-95p">
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" ngClass.gt-xs="p-t-40 w-70">
            <button class="bg-gray hover-dark p-v-25 f-600" ngClass.gt-xs="p-h-40" ngClass.xs="p-h-0" fxFlex
                    mat-raised-button
                    routerLink="/info/open-house">
              서비스 소개
            </button>
            <button class="bg-primary hover-primary p-v-25 f-600 l-h-0" fxFlex mat-raised-button
                    ngClass.gt-xs="p-h-40"
                    ngClass.xs="p-h-0" routerLink="/house/open">
              오픈부동산 보기
            </button>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="44" fxHide fxShow.gt-xs>
        <div ngClass.gt-xs="p-v-20 text-center">
          <div ngClass.xs="p-b-50" routerLink="/info/open-house">
            <div class="cursor" fxLayout="row wrap">
              <div *ngFor="let logo of partnerLogos" fxFlex="20" fxLayoutAlign="start center">
                <div class="p-2">
                  <img [src]="logo" alt="image" class="img-size-100p">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="showStoreLink">
  <section class="narrow-section banner-xs">
    <div ngClass.xs="p-20">
      <div class="p-b-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
        <img (click)="openPlayStore()" alt="Play Store Link" class="cursor img-size-50"
             src="assets/img/store/GooglePlay.png">
        <img (click)="openAppStore()" alt="AppStore Link" class="cursor img-size-50"
             src="assets/img/store/AppStore.png">
      </div>
      <div class="mat-body-2 text-center">
        체크팀 앱을 다운받으시면 등기변경 안내를 앱(푸시) 알림으로도 확인할 수 있습니다.
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>
